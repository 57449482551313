@font-face {
  font-family: 'Söhne Breit';
  src: url('/fonts/soehne-breit-leicht.woff2') format('woff2'),
    url('/fonts/SöhneBreit-Leicht.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/soehne-leicht.woff2') format('woff2'),
    url('/fonts/Söhne-Leicht.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/soehne-leicht-kursiv.woff2') format('woff2'),
    url('/fonts/Söhne-LeichtKursiv.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/soehne-buch.woff2') format('woff2'),
    url('/fonts/Söhne-Buch.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/soehne-buch-kursiv.woff2') format('woff2'),
    url('/fonts/Söhne-BuchKursiv.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/soehne-kraftig.woff2') format('woff2'),
    url('/fonts/Söhne-Kräftig.otf') format('opentype');
  font-weight: 500;
  font-style: medium;
}

@font-face {
  font-family: 'Söhne';
  src: url('/fonts/soehne-kraftig-kursiv.woff2') format('woff2'),
    url('/fonts/Söhne-KräftigKursiv.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}
body {
  overscroll-behavior: none;
}

figure,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  font-weight: normal;
}

cite {
  font-style: normal;
}

ol,
ul {
  padding-inline-start: unset;
  list-style-position: inside;
}

p {
  margin-block-start: unset;
  /* margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset; */
  /* font-weight: bold;  */
}

p:last-child {
  margin-block-end: unset;
}
